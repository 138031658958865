import { CLIENT_CONTRACT, CLIENT_PAYMENTS, GET_CLIENT, UPDATE_CLIENT_PASSWORD } from "core/utils/constants";
import { axiosInstance } from "../axios/axiosInstance";
import { AxiosError } from "axios";
import { TClientPageable, TNewClientBodyRequest } from "core/models/client";
import { TSellerBodyRequest } from "core/models/seller";
import { IPage, IResponseBody } from "core/models/utils";

const getDeal = async (): Promise<any> => {
  return await axiosInstance
    .get(CLIENT_CONTRACT)
    .then((response) => {
      if (response instanceof AxiosError) {
        return response.response?.data;
      }
      return response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getDealById = async (id: string): Promise<any> => {
  return await axiosInstance
    .get(`${CLIENT_CONTRACT}/${id}`)
    .then((response) => {
      if (response instanceof AxiosError) {
        return response.response?.data;
      }
      return response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getPayments = async (): Promise<any> => {
  return await axiosInstance
    .get(CLIENT_PAYMENTS)
    .then((response) => {
      if (response instanceof AxiosError) {
        return response.response?.data;
      }
      return response.data.data;
    })
    .catch((err) => {
      console.error(err);
    });
};


const setDefaultPassword = async (idClient: string): Promise<any> => {
  return await axiosInstance
    .get(`${UPDATE_CLIENT_PASSWORD} ${idClient}`)
    .then((response) => {
      if (response instanceof AxiosError) {
        return response.response?.data;
      }
      return response.data.data;
    })
    .catch((err) => {
      console.error(err);
    });
};


const getClients = async (
  clientPageable: TClientPageable
): Promise<IPage<TNewClientBodyRequest> | undefined> => {
  const response = await axiosInstance.get<IResponseBody<IPage<TNewClientBodyRequest>>>(
    GET_CLIENT, {
    params: {
      page: clientPageable.page,
      size: clientPageable.size,
      sort: clientPageable.sort,
      name: clientPageable?.name,
      cpforCnpj: clientPageable?.cpforCnpj,
    },
  });
  return response.data.data;
};


export const ClientService = {
  getDeal,
  getPayments,
  getDealById,
  setDefaultPassword,
  getClients,
};
