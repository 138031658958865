import { Box, Button } from "@mui/material";
import theme from "core/theme/theme";

import { formatCurrencyBR, formatDateBr, formatDocument, removeNonNumeric } from "core/utils/globalFunctions";
import html2pdf from "html2pdf.js";
import Image from "images/assets/paraContrato.png"

const monthsNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

interface Props {
  dowloadFunction?: boolean;
  clientName: string;
  clientDocument: string;
  clientAddressAsString: string;
  createDate?: string;
}

interface DateInfos {
  day: number;
  month: string;
  year: number;
}

export const ClientCoursesDeal = ({ clientDocument, clientName, clientAddressAsString, dowloadFunction, createDate }: Readonly<Props>) => {

  //? documento limpo
  const cleanDocument = removeNonNumeric(clientDocument);

  // ? pega o dia, nome do mês e ano atual
  function getActualDate(): DateInfos {
    const date = new Date();
    return {
      day: date.getDate(),
      month: monthsNames[date.getMonth()],
      year: date.getFullYear(),
    };
  }

  // ? data atual
  const actualDate: DateInfos = getActualDate();

  // ? função para baixar o pdf
  const downloadPDF = () => {
    const element = document.getElementById('contentCourses');
    const opt = {
      margin: 0,
      filename: 'contrato.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', background: theme.COLORS.WHITE_100 }}>
      <div id="contentCourses">
        <img src={Image} alt="Logo" style={{ width: '100%', height: 'auto' }} />
        <div style={{ margin: "4rem" }}>

          <p><h3 style={{ textAlign: 'center', padding: "0 10px" }}>CONTRATO PARTICULAR DE COMPRA E VENDA DE CURSO DE MENTORIA FINANCEIRA </h3></p>
          <p>
            Por este instrumento particular de <b>CONTRATO DE PRESTAÇÃO DE SERVIÇOS</b>, que entre si fazem, de um lado, POSITIVO PAYMENTS SERVICOS LTDA, CNPJ nº 44.341.929/0001-50, e-mail: contato@positivobra.com.br, com endereço na Avenida Epitácio Pessoa, nº 3701, Bairro Miramar, João Pessoa – PB, CEP. 58032-000, doravante denominada CONTRATADA, e de outro lado {clientName}, sob inscrição CPF nº {formatDocument(clientDocument)}, com endereço {clientAddressAsString}, doravante denominada <b>COMPRADOR(A)</b>.
          </p>
          <p>
            As partes resolvem celebrar o presente <b>CONTRATO DE COMPRA E VENDA DE CURSO DE MENTORIA FINANCEIRA,</b> mediante as cláusulas e condições a seguir estipuladas:
          </p>
          <p><b><u>CLÁUSULA 1 – DO OBJETO</u></b></p>
          <p>
            O presente contrato tem como objeto a venda, pela <b>VENDEDORA</b>, e a compra, pelo(a) <b>COMPRADOR(A)</b>, do <b>Curso de Mentoria Financeira</b> disponibilizado pela <b>VENDEDORA</b>, conforme as especificações a seguir:
          </p>
          <p>
            <b>Nome do Curso: Curso de Mentoria Financeira</b>
          </p>
          <p>
            <b>Valor Total:</b> R$ 297,00 (duzentos e noventa e sete reais)
          </p>
          <p><b><u>CLÁUSULA 2 – DO VALOR E FORMA DE PAGAMENTO</u></b></p>
          <p>
            O(a) <b>COMPRADOR(A)</b> compromete-se a pagar à <b>VENDEDORA</b> o valor total de R$ 297,00 (duzentos e noventa e sete reais), sem parcelamento, em uma única parcela, na data da assinatura deste contrato ou conforme estabelecido entre as partes.
          </p>
          <p>
            O pagamento será realizado via PIX, para os dados bancários fornecidos pela <b>VENDEDORA</b>.
          </p>
          <p><b><u>CLÁUSULA 3 – DA ENTREGA DO CURSO</u></b></p>
          <p>
            A <b>VENDEDORA</b> se compromete a disponibilizar ao(à) <b>COMPRADOR(A</b>) o acesso ao curso de mentoria financeira no prazo de até 2 dias úteis após a confirmação do pagamento integral.
          </p>
          <p><b><u>CLÁUSULA 4 – DAS OBRIGAÇÕES DA VENDEDORA</u></b></p>
          <p style={{marginTop: "30px"}}>
            A <b>VENDEDORA</b> se compromete a:
          </p>
          <p>
            a) Disponibilizar o conteúdo completo do curso de mentoria financeira conforme especificado nes	te contrato;
          </p>
          <p style={{ textAlign: "center" }}>
            Endereço: Rua Maria das Neves Silva, nº 310, Bairro Ponta Campina, Cabedelo – PB, CEP. 58100.000
          </p>
          <p style={{ textAlign: "center" }}>
            Instagram: positivobra
          </p>
          <p>
            b) Garantir o acesso às aulas e materiais durante o período estipulado; c) Oferecer suporte técnico e pedagógico durante o período de mentoria.
          </p>
          <p><b><u>CLÁUSULA 5 – DAS OBRIGAÇÕES DO(A) COMPRADOR(A)</u></b></p>
          <p>
            O(a) <b>COMPRADOR(A)</b> compromete-se a:
          </p>
          <p>
            a) Efetuar o pagamento integral do curso na forma e prazo estabelecidos neste contrato;
          </p>
          <p>
            b) Utilizar o material disponibilizado de forma ética e responsável, respeitando os direitos autorais da <b>VENDEDORA</b>.
          </p>
          <p><b><u>CLÁUSULA 6 – DO CANCELAMENTO E REEMBOLSO</u></b></p>
          <p>
            O cancelamento da compra deverá ser solicitado por escrito, no prazo de até 7 (sete) dias após a confirmação do pagamento, conforme o Código de Defesa do Consumidor (Art. 49). Caso o cancelamento ocorra dentro desse prazo, o(a) <b>COMPRADOR(A)</b> terá direito ao reembolso integral do valor pago.
          </p>
          <p><b><u>CLÁUSULA 7 – DA RESCISÃO</u></b></p>
          <p>
            O presente contrato poderá ser rescindido por qualquer uma das partes, mediante comunicação prévia por escrito, nos seguintes casos:
          </p>
          <p>
            a) Descumprimento de qualquer das cláusulas aqui pactuadas;
          </p>
          <p>
            b) Inadimplemento do pagamento por parte do(a) <b>COMPRADOR(A)</b>.
          </p>
          <p><b><u>CLÁUSULA 8 – DAS DISPOSIÇÕES FINAIS</u></b></p>
          <p>
            As partes elegem o foro da Comarca de João Pessoa, Paraíba, para dirimir quaisquer dúvidas ou controvérsias oriundas deste contrato, com renúncia expressa de qualquer outro, por mais privilegiado que seja.
          </p>
          <p>
            E por estarem justas e acordadas, as partes assinam o presente contrato em duas vias de igual teor e forma, na presença de duas testemunhas, tudo quanto neste instrumento particular foi lavrado, obrigando-se as partes a cumprir o presente contrato.
          </p>
          <p> </p>
          <p style={{ marginTop: "100px", textAlign: "center" }}>
            João Pessoa – PB, {createDate ? formatDateBr(createDate) : `${actualDate.day} de ${actualDate.month} de 2024`}.
          </p>
          <p style={{ textAlign: "center" }}>
            Endereço: Rua Maria das Neves Silva, nº 310, Bairro Ponta Campina, Cabedelo – PB, CEP. 58100.000
          </p>
          <p style={{ textAlign: "center" }}>
            Instagram: positivobra
          </p>

          <p style={{ marginTop: "90px", textAlign: "center" }}>
            ______________________________________________________________
          </p>
          <p style={{ textAlign: "center" }}>
            <b>
              Assinatura do (a) Contratante
            </b>
          </p>
          <p style={{ marginTop: "60px", textAlign: "center" }}>
            ______________________________________________________________
          </p>
          <p style={{ textAlign: "center" }}>
            <b>
              Assinatura da Contratada
            </b>
          </p>
          <p style={{ marginTop: "60px" }}>
            Testemunha:
          </p>
          <p>
            Testemunha:
          </p>
        </div>
      </div>
      {dowloadFunction && <Button sx={{ margin: 'auto' }} onClick={downloadPDF}>Download PDF</Button>}
    </div>
  );
}